<template>
  <b-card class="content-history px-3 py-4" no-body>
    <strong>Fluxo de avaliação</strong>
    <hr />
    <div class="history">
      <div v-for="(history, index) in histories" :key="index" class="mb-3">
        <i class="d-block">{{ history.name }}</i>
        <b-button
          size="sm"
          block
          :disabled="!history.enabled"
          :variant="history.enabled ? 'link' : 'light'"
          class="px-0 ml-1 text-left my-1 d-flex align-items-center button-history"
          v-for="(child, i) in history.children"
          :key="i"
          target="_blank"
          :to="history.enabled ? `${history.base}/${child.url}` : ''"
        >
          <b-icon
            :icon="variantStatus(child.status).icon"
            :variant="variantStatus(child.status).variant"
            class="mr-2"
          />
          <span> {{ child.name }}</span>
        </b-button>
        <hr />
      </div>
    </div>
  </b-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      histories: [],
      service_id: "",
    };
  },
  methods: {
    ...mapActions(["get_service_validation_history"]),
    getServiceValidationHistory() {
      this.get_service_validation_history({ service_id: this.service_id }).then(
        ({ history }) => {
          this.histories = history;
        }
      );
    },
    variantStatus(status) {
      let icon;
      let variant;
      switch (status) {
        case 1:
          icon = "check-circle";
          variant = "success";
          break;
        case 2:
          icon = "x-circle";
          variant = "danger";
          break;
        case 3:
          icon = "clock";
          variant = "warning";
          break;
      }
      return {
        icon,
        variant,
      };
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.getServiceValidationHistory();
  },
};
</script>
<style lang="scss">
.content-history {
  height: 67vh;
  overflow: hidden;
  .history {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .button-history {
    background: transparent !important;
    &:disabled {
      color: #f8f9fa !important;
      opacity: 0.4;
    }
  }
}
</style>
