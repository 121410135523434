<template>
  <b-card class="content-attachments">
    <strong>Anexos</strong>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button
        size="sm"
        variant="primary"
        class="mb-2"
        @click="handleAddAttachment()"
      >
        Adicionar arquivo
      </b-button>
    </div>
    <b-card
      no-body
      class="p-2 mb-2"
      style="border-radius: 0"
      v-for="(attachment, index) in attachments"
      :key="index"
    >
      <b-media class="d-flex align-items-center">
        <template #aside>
          <img :src="handleGetIcon(attachment)" width="30" />
        </template>

        <div class="d-flex justify-content-between w-100">
          <small class="mt-0 col-8 p-0">
            {{ attachment.file_name }}
          </small>
          <div class="d-flex icon-action">
            <b-icon
              icon="download"
              class="pointer"
              @click="handleDownloadURI(attachment)"
            />
            <b-icon
              icon="trash"
              class="ml-2 pointer"
              @click="handleToggleDelete(attachment.id)"
            />
          </div>
        </div>
      </b-media>
    </b-card>
    <b-modal
      id="add-attachments-validation"
      size="md"
      hide-footer
      centered
      title="Adicionar anexos à validação"
    >
      <file-upload @upload="handleUploadAttachment" />
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="close()">Cancelar</b-button>
        <b-button variant="primary" @click="handleDeleteProfile()">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import { mapActions } from "vuex";
import FileUpload from "@/components/FileUpload";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      attachments: [],
      service_id: "",
      selectedId: "",
      message:
        "Você irá deletar o anexo, essa ação não poderá ser desfeita. Deseja continuar?",
    };
  },
  methods: {
    ...mapActions([
      "show_delete",
      "get_service_validation_attachments",
      "delete_service_validation_attachments",
      "add_service_validation_attachments",
    ]),
    handleToggleDelete(id) {
      this.selectedId = id;
      this.show_delete({
        in_delete: true,
        message: this.message,
      });
    },
    handleDownloadURI({ file_name, file_type, url }) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${file_name}.${file_type}`;
          link.click();
        })
        .catch(console.error);
    },
    handleGetIcon(attachment) {
      const files = ["docx", "pdf", "txt", "xls"];
      if (files.includes(attachment.file_type)) {
        return require(`@/assets/images/icons/${attachment.file_type}.png`);
      }
      switch (true) {
        case ["jpg", "jpeg", "png", "gif"].includes(attachment.file_type):
          return require(`@/assets/images/icons/image.png`);
        case ["xls", "xlsx"].includes(attachment.file_type):
          return require(`@/assets/images/icons/xls.png`);
        case ["m4a", "mp3", "mp4", "wav", "wma", "aac"].includes(
          attachment.file_type
        ):
          return require(`@/assets/images/icons/audio.png`);
        default:
          return require(`@/assets/images/icons/file.png`);
      }
    },
    handleUploadAttachment(file) {
      this.add_service_validation_attachments({
        service_id: this.service_id,
        file: file[0]?.id,
      }).then(() => {
        this.uploadedFiles = this.$bvModal.hide("add-attachments-validation");
        this.getAttachments();
        this.$forceUpdate();
      });
    },
    handleAddAttachment(month) {
      this.selectedMonth = month;
      this.$bvModal.show("add-attachments-validation");
    },
    getAttachments() {
      this.get_service_validation_attachments({
        service_id: this.service_id,
      }).then(({ results }) => {
        this.attachments = results;
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.delete_service_validation_attachments({
          service_id: this.service_id,
          id: this.selectedId,
        }).then(() => {
          this.getAttachments();
        });
        this.show_delete({
          in_delete: false,
          message: this.message,
        });
      }
    });
    this.getAttachments();
  },
};
</script>
<style lang="scss">
.content-attachments {
  height: 67vh;
  width: 100%;
}
</style>
