<template>
  <div>
    <b-card class="content-conversation no-border px-md-3 py-md-4" no-body>
      <div class="d-flex align-items-center justify-content-between pb-2">
        <strong>Atualizações</strong>
        <div
          class="d-flex gap-2 align-items-center icon-action"
          v-if="is_mobile"
        >
          <b-icon
            @click="$emit('openView', 'history')"
            icon="list-check"
            class="h5 m-0 mr-3 pointer"
          />
          <b-icon
            @click="$emit('openView', 'attachments')"
            icon="paperclip"
            class="h5 m-0 pointer"
          />
        </div>
      </div>
      <hr />
      <div ref="conversations" class="comments">
        <div v-for="(comment, index) in conversations" :key="index">
          <section class="mr-md-4 my-2">
            <section
              class="
                d-md-flex
                align-items-start
                justify-content-start
                flex-md-row
              "
            >
              <avatar
                class="mr-2 mb-md-0 mb-2"
                name="Maat Admin"
                style="width: 30px"
                v-if="comment.is_maat"
              />
              <avatar
                v-else-if="!comment.is_maat && user.enterprise"
                class="mr-2 mb-md-0 mb-2"
                :name="user.enterprise.social_reason"
                style="width: 30px"
              />
              <b-alert
                variant="light"
                show
                class="w-100 mb-0 py-0 p-3"
                v-html="renderHtml(comment)"
              />
            </section>
            <hr class="w-50" />
          </section>
        </div>
      </div>
    </b-card>
    <b-card class="mt-2 no-border" body-class="p-md-3 p-0">
      <div>
        <section
          class="
            mb-md-0
            w-100
            d-md-flex
            align-items-center
            justify-content-end
            flex-md-row
            text-right
            mr-2
          "
        >
          <b-form-textarea
            class="mt-1 pr-5 mr-md-2"
            v-model="message"
            :class="error && 'is-invalid'"
            label="title"
            placeholder="Digite seu comentário"
            ref="commentArea"
          >
          </b-form-textarea>
          <b-button
            variant="primary"
            size="sm"
            class="position-md-absolute mr-md-2 mt-2 mt-md-0"
            @click="handleSendMessage()"
          >
            Enviar
          </b-button>
        </section>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      message: "",
      error: false,
      service_id: "",
      conversations: [],
      currentPage: 1,
    };
  },

  methods: {
    ...mapActions([
      "add_service_validation_conversation",
      "get_service_validation_conversation",
    ]),

    renderHtml(comment) {
      return `
      <div>
       <div class="d-flex align-items-start justify-content-between">
        <strong class="col-8 p-0">${
          comment.is_maat ? "Maat Admin" : this.user?.enterprise?.social_reason
        } </strong>
        <span class="text-info" style="font-size: 10px ;white-space: nowrap;">${this.moment(
          String(comment.created_at)
        ).format("DD/MM/YY HH:mm")}</span>
        </div>
       <div>
         ${comment.message}  
       </div>
      </div>
      `;
    },

    scrollTo(scroll) {
      const conversation = this.$refs.conversations;
      if (scroll != conversation.clientHeight)
        conversation.scrollIntoView({ behavior: "smooth" });
      conversation.scrollTop = scroll;
    },
    getServiceValidationConversation(scrollToEnd) {
      const conversation = this.$refs.conversations;
      const previousScrollHeight = conversation.scrollHeight;
      this.get_service_validation_conversation({
        service_id: this.service_id,
        current_page: this.currentPage,
      }).then(({ results }) => {
        if (results) {
          if (this.currentPage !== 1) {
            this.conversations = [...results.reverse(), ...this.conversations];
            return;
          }
          this.conversations = results.reverse();
          return;
        }
        this.currentPage -= 1;
      });
      this.$nextTick(() => {
        if (scrollToEnd) this.scrollTo(conversation.scrollHeight);
        else this.scrollTo(conversation.scrollHeight - previousScrollHeight);
      });
    },

    handleSendMessage() {
      this.add_service_validation_conversation({
        service_id: this.service_id,
        message: this.message,
        created_at: new Date().toISOString(),
      }).then(({ status }) => {
        if (status === 201) {
          this.conversations.push({
            is_maat: this.user.is_admin,
            message: this.message,
          });
          this.currentPage = 1;
          this.getServiceValidationConversation(true);
        } else {
          this.error = true;
        }
        return;
      });
      this.message = "";
      this.$refs.commentArea.focus();
    },
  },
  computed: {
    ...mapGetters(["user", "is_mobile"]),
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.getServiceValidationConversation(true);
    const conversation = this.$refs.conversations;
    conversation.addEventListener("scroll", () => {
      if (conversation.scrollTop === 0) {
        this.currentPage += 1;
        this.getServiceValidationConversation(false);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.content-conversation {
  height: 56vh;
  @media (max-width: $break-md) {
    height: 52vh;
    background: $bg-2;
    padding: 10px;
  }
  .comments {
    overflow: auto;
  }
}
</style>
