<template>
  <section class="provider-validation ">
    <iframe
      v-if="service_id && provider_id"
      :src="`${report_url}/${service_id}/${provider_id}`"
      frameborder="0"
      width="100%"
      height="100%"
      class="min-h-68 iframe"
    ></iframe>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { url } from "@/services/api";

export default {
  data() {
    return {
      report_url: `${process.env.VUE_APP_MICROSERVICE_URL}/report`,
      service_id: "",
      provider_id: "",
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.user.enterprise?.id;
  },
};
</script>
<style lang="scss" scoped>
.iframe {
  box-shadow: inset 0px 0px 14px 4px #eee;
}
</style>
