<template>
  <div
    class="
      align-items-start
      d-flex
      justify-content-start
      w-100
      min-h-50
      mt-md-4 mt-1
      flow-validation
    "
  >
    <history
      v-if="history"
      class="p-0 content-history animated faster"
      :class="is_mobile && 'fadeInLeft'"
    />
    <conversation
      @openView="handleOpenVisualization"
      class="col-md-6 col-12 p-0 mx-md-2"
    />
    <attachments
      v-if="attachments"
      class="min-h-58 content-attachments animated faster"
      :class="is_mobile && 'fadeInLeft'"
    />
    <div
      v-if="(attachments || history) && is_mobile"
      class="backdrop"
      @click="handleCloseVisualization"
    />
  </div>
</template>
<script>
import Conversation from "./Conversation.vue";
import History from "./History";
import Attachments from "./Attachments";
import { mapGetters } from "vuex";

export default {
  components: {
    History,
    Conversation,
    Attachments,
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
  data() {
    return {
      attachments: false,
      history: false,
      service_id: "",
    };
  },
  methods: {
    handleOpenVisualization(element) {
      if (!this.is_mobile) {
        this.attachments = true;
        this.history = true;
        return;
      }
      if (element === "attachments") {
        this.attachments = true;
      }
      if (element === "history") {
        this.history = true;
      }
    },
    handleCloseVisualization() {
      this.history = false;
      this.attachments = false;
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.handleOpenVisualization("none");
  },

  watch: {
    is_mobile() {
      this.handleOpenVisualization("none");
    },
  },
};
</script>
<style lang="scss">
.flow-validation {
  .content-history {
    min-width: 264px;
    @media (max-width: $break-md) {
      position: fixed;
      left: 0;
      z-index: +8;
      height: 100%;
      width: 80%;
      top: 50px;
      border: none;
      border-radius: 0;
      .history {
        padding-bottom: 50px;
      }
    }
  }
  .content-attachments {
    @media (max-width: $break-md) {
      position: fixed;
      left: 0;
      z-index: +8;
      height: 100%;
      top: 50px;
      width: 80%;
      border: none;
      border-radius: 0;
    }
  }
  .backdrop {
    position: absolute;
    z-index: +7;
  }
}
</style>
